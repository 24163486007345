import React, {useEffect, useState} from "react";
import {navigate} from "gatsby";
import {Button} from "reactstrap";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import Form from "reactstrap/lib/Form";
import {StringParam, useQueryParam} from "use-query-params";
import clsx from "clsx";
import HorizontalLoader from "~/components/Loaders/HorizontalLoader";
import authenticationService from "~/utils/api/v1/authenticationService";
import Auth from "~/utils/auth/auth";
import {cleanUrl} from "~/utils/validations/urls";

const avatarLogin = require(`../assets/branding/isotipo.png`).default;
const auth = new Auth();

// Constants
const ERROR_MESSAGES = {
    NOT_LOGGED_IN: "Debes iniciar sesión",
    INSUFFICIENT_PERMISSIONS: "Este usuario no tiene permisos suficientes para esto",
    LOGIN_FAILED: "Uh oh, usuario o contraseña incorrectos!",
};

const REDIRECT_DEFAULT = "/dashboard/";

// Utility Functions
const handleScanForErrors = (errorType: string | undefined): string => {
    switch (errorType) {
        case "NotLoggedIn":
            return ERROR_MESSAGES.NOT_LOGGED_IN;
        default:
            return "";
    }
};

const isLoginEnabled = (credentials: {
    email: string;
    password: string
}): boolean =>
    [credentials.email.length > 0, credentials.password.length > 0].every(Boolean);

const AuthenticatingOverlay = () => (
    <div
        className="absolute inset-0 bg-white bg-opacity-95 flex items-center justify-center z-50 rounded-2xl">
        <HorizontalLoader/>
    </div>
);

const Login = () => {
    const [credentials, setCredentials] = useState({email: "", password: ""});
    const [authenticating, setAuthenticating] = useState(false);
    const [error, setError] = useState("");
    const [queryError] = useQueryParam("error", StringParam);
    const [redirect] = useQueryParam("redirect", StringParam);

    useEffect(() => {
        if (auth.isLoggedIn()) {
            navigate(REDIRECT_DEFAULT);
        } else {
            setError(handleScanForErrors(queryError));
        }
    }, [queryError]);

    // Event Handlers
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setCredentials((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const requestLogin = async () => {
        setError("");
        setAuthenticating(true);
        try {
            const authenticated = await authenticationService.login(credentials);

            if (!authenticated["https://api.saludando.cl/roles"].includes("superadmin")) {
                setError(ERROR_MESSAGES.INSUFFICIENT_PERMISSIONS);
            } else {
                navigate(redirect ? cleanUrl(redirect) : REDIRECT_DEFAULT);
            }
        } catch {
            setError(ERROR_MESSAGES.LOGIN_FAILED);
        } finally {
            setAuthenticating(false);
        }
    };

    // JSX
    return (
        <div className="w-full min-h-screen p-5 pb-24 bg-gray-200">
            <div className="mx-auto max-w-screen-xl flex">
                <div
                    className="bg-white w-full max-w-[500px] min-w-[200px] rounded-2xl mx-auto mt-12 relative px-6 py-10 sm:px-20 sm:py-20">
                    {authenticating && <AuthenticatingOverlay/>}
                    <div className="text-center flex flex-col items-center">
                        <img src={avatarLogin} alt="examedi-isotipo"
                             className="w-10 h-10 object-contain"/>
                        <h1 className="mt-5">Ingresa a tu cuenta</h1>
                        <div className="mt-5">
                            <p className="mb-2.5 text-red-500 text-sm leading-4">{error}</p>
                        </div>
                    </div>
                    <Form
                        autoComplete="new-password"
                        onSubmit={(e) => {
                            e.preventDefault();
                            requestLogin();
                        }}
                    >
                        <FormGroup>
                            <Input
                                type="email"
                                placeholder="Email"
                                value={credentials.email}
                                name="email"
                                onChange={handleInputChange}
                                className="h-11 mb-4"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="password"
                                placeholder="Password"
                                value={credentials.password}
                                name="password"
                                onChange={handleInputChange}
                                className="h-11 mb-4"
                            />
                        </FormGroup>
                        <Button
                            type="submit"
                            disabled={!isLoginEnabled(credentials)}
                            className={clsx(
                                "w-full flex items-center justify-center rounded font-bold mt-5 !bg-[#039BE5] border !border-[#039BE5] text-white hover:!bg-[#039BE5]/80",
                                !isLoginEnabled(credentials) && "!bg-gray-300 opacity-50"
                            )}
                        >
                            Login
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Login;
