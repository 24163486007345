import React, { useEffect, useState } from "react";

import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import { Link } from "gatsby";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import Tabs from "~/components/Common/Tabs";
import LoadingError from "~/components/Loaders/LoadingError";
import NurseCard from "~/components/Nurses/NurseCard";
import nurseService from "~/utils/api/v1/nurseService";
import { CODE_TO_COUNTRY, COUNTRY_CODES } from "~/utils/data/constants";
import { Locale } from "~/utils/interfaces/Locale";
import { NurseName } from "~/utils/interfaces/Nurse";

function HealthTeamList() {
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchedInactive, setFetchedInactive] = useState<boolean>(false);
  const [showingActive, setShowingActive] = useState<boolean>(true);
  const [showingOnlyOperative, setShowingOnlyOperative] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [workers, setWorkers] = useState<NurseName[]>([]);
  const [inactiveWorkers, setInactiveWorkers] = useState<NurseName[]>([]);
  const [visibleWorkers, setVisibleWorkers] = useState<NurseName[]>([]);
  const [country, setCountry] = useState<Locale>(COUNTRY_CODES[0]);

  const handlerOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().trim();
    const currentWorkers = showingActive ? workers : inactiveWorkers;
    if (value === "") {
      setVisibleWorkers(currentWorkers);
      return;
    }
    const filteredWorkers = currentWorkers.filter((worker: NurseName) => {
      const targetProp = `${worker.names} ${worker.last_names}`;
      return targetProp
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(value);
    });
    setVisibleWorkers(filteredWorkers);
  };

  const fetchActiveData = async (country: Locale, operativeFilter:string): Promise<void> => {
    setLoading(true);
    setError('')
    try {
      const req = await nurseService.fetchActiveNurses(country, operativeFilter);
      setWorkers(req.data.data);
      setVisibleWorkers(req.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }

    setLoading(false);
  };

  const fetchInactiveData = async (country: Locale, operativeFilter:string): Promise<void> => {
    setLoading(true);
    setError('')

    try {
      const req = await nurseService.fetchInactiveNurses(country, operativeFilter);
      setFetchedInactive(true);
      setInactiveWorkers(req.data.data);
      setVisibleWorkers(req.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    const operativeFilter = showingOnlyOperative? 'operative': 'regular';
    fetchActiveData(country, operativeFilter);
  }, [country, showingOnlyOperative]);

  useEffect(() => {
    const operativeFilter = showingOnlyOperative? 'operative': 'regular';
    if (!showingActive) {
      if (!fetchedInactive) {
        fetchInactiveData(country, operativeFilter);
      } else {
        setVisibleWorkers(inactiveWorkers);
      }
    } else {
      setVisibleWorkers(workers);
    }
  }, [showingActive, showingOnlyOperative, country]);

  return (
    <PrivateRoute>
      <div
        className={clsx(
          "w-full",
          "px-5 py-4",
          "flex flex-col gap-y-5 bg-examedi-white-pure",
          "rounded-lg border border-examedi-gray-4",
        )}
      >
        <h1 className="text-4xl font-light">Health Team</h1>
        <Tabs
          elements={COUNTRY_CODES}
          parser={(value: string) => CODE_TO_COUNTRY[value as Locale]}
          onSelectTab={(value: string) => setCountry(value as Locale)}
        >
          <>
            <div className="w-full flex-col my-4">
              <div className="w-full flex gap-x-16 items-center">
                <Link to="/health-team/announcements">
                  <button
                    className={clsx(
                      "w-64 px-3.5 py-2.5",
                      "text-center text-examedi-white-pure",
                      "bg-examedi-blue-strong hover:bg-examedi-blue-strong-50",
                      "rounded-md",
                      "transition-all duration-100 ease-in-out",
                    )}
                  >
                    Anuncios
                  </button>
                </Link>
                <Link to="/health-team/tracking">
                  <button
                    className={clsx(
                      "w-64 px-3.5 py-2.5",
                      "text-center text-examedi-white-pure",
                      "bg-examedi-blue-strong hover:bg-examedi-blue-strong-50",
                      "rounded-md",
                      "transition-all duration-100 ease-in-out",
                    )}
                  >
                    Trackeo
                  </button>
                </Link>
              </div>
              <div className="w-full flex justify-between items-center mt-4">
                <TextField
                  fullWidth
                  label="Nombre"
                  variant="standard"
                  onChange={handlerOnChange}
                  className="w-1/3"
                />
                <FormControl>
                  <InputLabel id="status-label">Estado</InputLabel>
                  <Select
                    disabled={loading}
                    value={`${showingActive}`}
                    onChange={(e) => {
                      setShowingActive(e.target.value === "true");
                    }}
                    fullWidth
                  >
                    <MenuItem
                      key="active"
                      value="true"
                    >
                      Activo
                    </MenuItem>
                    <MenuItem
                      key="inactive"
                      value="false"
                    >
                      Inactivo
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel id="status-label">Tipo de cita</InputLabel>
                  <Select
                    disabled={loading}
                    value={`${showingOnlyOperative}`}
                    onChange={(e) => {
                      setShowingOnlyOperative(e.target.value === "true");
                    }}
                    fullWidth
                  >
                    <MenuItem
                      key="operative"
                      value="true"
                    >
                      Operativos
                    </MenuItem>
                    <MenuItem
                      key="regular"
                      value="false"
                    >
                      Regular
                    </MenuItem>
                  </Select>
                </FormControl>
                <Link
                  to="/health-team/new"
                  className="w-1/3"
                >
                  <button
                    className={clsx(
                      "w-64 px-3.5 py-2.5",
                      "text-center text-examedi-white-pure",
                      "bg-examedi-blue-strong hover:bg-examedi-blue-strong-50",
                      "rounded-md",
                      "transition-all duration-100 ease-in-out",
                    )}
                  >
                    Crear nuevo
                  </button>
                </Link>
              </div>
            </div>
            <LoadingError
              loading={loading}
              error={error}
            />
            <p className={'text-sm m-1 text-gray-2'}>resultados: {visibleWorkers?.length? visibleWorkers?.length: 0}</p>
            {!loading && (
              <div className="grid grid-cols-1 md:grid-cols-2 2lg:grid-cols-3 1xl:grid-cols-4 3xl:grid-cols-5 gap-8">
                {visibleWorkers.map((worker: NurseName, index: number) => (
                  <NurseCard
                    key={index}
                    worker={worker}
                  />
                ))}
              </div>
            )}
          </>
        </Tabs>
      </div>
    </PrivateRoute>
  );
}

export default HealthTeamList;
