import React, { useEffect, useRef, useState } from "react";

import {
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Pagination, Skeleton } from "antd";
import { AxiosError } from "axios";
import Swal from "sweetalert2";

import CancelAppointment from "~/components/Appointments/Cancel/CancelAppointment";
import { Actionables, AppointmentInfo, Payments } from "~/components/Appointments/Detail";
import PatientInformationList from "~/components/Appointments/Detail/PatientInformationList";
import PaymentDetails from "~/components/Appointments/Detail/PaymentDetails";
import OperativeData from "~/components/Appointments/OperativeData";
import ReceiptSection from "~/components/Appointments/ReceiptSection";
import AppointmentView from "~/components/AppointmentsV2/AppointmentView";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingSpinnerIcon from "~/components/icons/LoadingSpinnerIcon";
import AppointmentNursePaymentCard from "~/components/nursePayments/AppointmentNursePaymentCard";
import NewPatient from "~/components/Patients/NewPatient";
import usePermissions from "~/hooks/usePermissions";
import {
  appointmentAPI,
  appointmentCommentAPI,
  appointmentTimelineEventAPI,
  labAPI,
  paymentAPI,
  receiptAPI,
} from "~/utils/api/v2";
import { startAuction } from "~/utils/api/v2/auctions";
import { COUNTRIES, COUNTRY_TO_TIMEZONE } from "~/utils/data/constants";
import {
  AppointmentTimelineEvent,
  AppointmentV2,
  Comment,
  Payment,
  PaymentEventLog,
  Receipt,
} from "~/utils/interfaces/Appointment";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";
import { Laboratory } from "~/utils/interfaces/Laboratory";
import { Locale } from "~/utils/interfaces/Locale";
import { numberWithPeriods } from "~/utils/numbers";
// Define tab indexes
const TABS = {
  PATIENTS: 0,
  PAYMENTS: 1,
  RECEIPTS: 2,
  COMMENTS: 3,
  EVENTS: 4,
  AUCTIONS: 5,
};

interface AppointmentDetailProps {
  id: string;
}

const AppointmentDetail = ({ id }: AppointmentDetailProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(TABS.PATIENTS);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasImage, setHasImage] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();
  const [comments, setComments] = useState<Comment[]>([]);
  const [loadingComments, setLoadingComments] = useState<boolean>(false);
  const [reloadOperativeData, setReloadOperativeData] = useState(true);
  const [appointment, setAppointment] = useState<AppointmentV2 | undefined>();
  const [labs, setLabs] = useState<Laboratory[]>([]);
  const cancelComponentRef = useRef<any>(null);
  const { isNursePaymentAdmin, isNursePaymentEdit } = usePermissions();

  const [apptPatientLoading, setApptPatientLoading] = useState<boolean>(false);
  const [apptPatientError, setApptPatientError] = useState<AxiosError | null>(null);

  const [appointmentPatients, setAppointmentPatients] = useState<AppointmentPatientV2[]>([]);
  const [timelineEvents, setTimelineEvents] = useState<AppointmentTimelineEvent[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [auctionLoading, setAuctionLoading] = useState<boolean>(false);
  const [hasOngoingAuction, setHasOngoingAuction] = useState<boolean>(false);
  const [auction, setAuction] = useState<string | null>(null);

  const reloadPatients = async (page = 1) => {
    setApptPatientLoading(true);
    setApptPatientError(null);
    try {
      const appointmentPatientReq = await appointmentAPI.appointmentPatientsPaginated(id, page);
      setAppointmentPatients(appointmentPatientReq.data.results);
    } catch (error) {
      setApptPatientError(error as AxiosError);
    } finally {
      setApptPatientLoading(false);
    }
  };

  const reloadPayments = async () => {
    try {
      const appointmentRes = await appointmentAPI.get(id);
      setAppointment(appointmentRes.data);
      const paymentsRes = await paymentAPI.list(appointmentRes.data.payments);
      setPayments(paymentsRes.data);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    const fetchAppointmentRelatedData = async () => {
      if (appointment) {
        if (appointment.appointment_patients.length > 0) {
          reloadPatients(currentPage);
        }
        try {
          if (appointment.payments.length > 0) {
            const paymentsReq = await paymentAPI.list(appointment.payments);
            setPayments(paymentsReq.data);
          }
          if (appointment.receipts.length > 0) {
            const receiptsReq = await receiptAPI.list(appointment.receipts);
            setReceipts(receiptsReq.data);
          }
          const commentReq = await appointmentAPI.listComments(id);
          setComments(commentReq.data);
        } catch (err) {
          setError(err);
        }
      }
    };
    fetchAppointmentRelatedData();
  }, [appointment]);

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await appointmentAPI.get(id);
      setAppointment(res.data);
      setHasOngoingAuction(res.data.is_on_auction);
      setAuction(res.data.auction_id);
      setTimelineEvents(res.data.timeline_events);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const fecthLabs = async (): Promise<void> => {
    try {
      const labs = await labAPI.list({ country: appointment?.country as Locale });
      setLabs(labs || []);
    } catch (err) {
      setError(err);
    }
  };

  const createComment = async (): Promise<void> => {
    try {
      Swal.fire({
        icon: "warning",
        title: "Ingresa el comentario que quieres dejar",
        input: "textarea",
        confirmButtonText: "Comentar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (res) => {
        if (res.isDenied || res.isDismissed) {
          return;
        }

        if (res.value === null || res.value === "") {
          // eslint-disable-next-line no-alert
          alert("el comentario debe tener un valor");
        } else {
          setLoadingComments(true);
          try {
            const req = await appointmentAPI.createComment(id, {
              comment: res.value,
            });
            setComments((prevComments) => [...prevComments, req.data]);
          } catch (err) {
            setError(err);
          }
          setLoadingComments(false);
        }
      });
    } catch (err) {
      setError(err);
    }
  };

  const deleteComment = async (commentId: string): Promise<void> => {
    try {
      const res = await Swal.fire({
        icon: "warning",
        title: "¿Estás seguro de que quieres eliminar este comentario?",
        confirmButtonText: "Si, eliminar",
        showCancelButton: true,
        cancelButtonText: "No",
      });
      if (res.value) {
        await appointmentCommentAPI.delete(commentId);
        setComments((prevComments) => {
          return prevComments.filter((comment) => comment.id !== commentId);
        });
        await Swal.fire({
          icon: "success",
          title: "Comentario eliminado",
        });
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "No puedes eliminar un comentario que no es tuyo",
      });
    }
  };

  const deleteEvent = async (eventId: string): Promise<void> => {
    try {
      const res = await Swal.fire({
        icon: "warning",
        title: "¿Estás seguro de que quieres eliminar este evento?",
        confirmButtonText: "Si, eliminar",
        showCancelButton: true,
        cancelButtonText: "No",
      });
      if (res.value) {
        await appointmentTimelineEventAPI.delete(eventId);
        setTimelineEvents((prevTimelineEvents) => {
          return prevTimelineEvents.filter((event) => event.id !== eventId);
        });
        await Swal.fire({
          icon: "success",
          title: "Evento eliminado",
        });
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "No puedes elminar un comentario que no es tuyo",
      });
    }
  };

  useEffect((): void => {
    fetchData();
    fecthLabs();
  }, []);

  const translateAction = (tag: string, event_info?: PaymentEventLog) => {
    if (event_info?.patient_info)
      return [
        `(${event_info?.patient_info?.age}) ${event_info?.patient_info?.full_name}`,
        `${event_info?.patient_info?.patient_id || ""}`,
      ];
    if (event_info?.item_id) {
      return [`${event_info?.item_info?.name} (${event_info?.item_id})`, event_info?.action];
    }
    if (event_info?.pack_id) {
      return [`${event_info?.pack_info?.name} (${event_info?.pack_id})`, event_info?.action];
    }
    return [""];
  };
  const translatePayment = (event_info?: PaymentEventLog) => {
    const iconOptions = {
      undefined: <WarningAmberIcon color="warning" />,
      true: <CheckIcon color="success" />,
      false: <CloseIcon color="error" />,
    };
    const messageChoices = {
      undefined: "información no registrada",
      true: "",
      false: `${numberWithPeriods((event_info?.cost_services || 0) - (event_info?.paid || 0))}`,
    };
    // @ts-expect-error-error
    const icon = iconOptions[event_info?.up_to_date];
    // @ts-expect-error-error
    const message = messageChoices[event_info?.up_to_date];
    return (
      <Tooltip
        title={
          event_info?.up_to_date !== undefined
            ? `suma de detalles: ${numberWithPeriods(event_info?.cost_services)}, Pagado: ${numberWithPeriods(
                event_info?.paid,
              )}`
            : ""
        }
      >
        <div>
          {icon} {message}
        </div>
      </Tooltip>
    );
  };
  const renderTimelineEvents = () => {
    const columns = ["created_at", "tag", "blame", "pagos al día", "comentario", "acción"];
    return (
      <div className="mt-5">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {timelineEvents.map((event: AppointmentTimelineEvent, i: number) => (
                <TableRow key={`${i}`}>
                  <TableCell key="created-at">{parseDatetime(event.created_at)}</TableCell>
                  <TableCell key="tag">{event.tag}</TableCell>
                  <TableCell key="owner-full-name">{event.owner?.fullname}</TableCell>
                  <TableCell key="event-info">{translatePayment(event?.event_info)}</TableCell>
                  <TableCell key="comentary">
                    {translateAction(event.tag, event?.event_info).map((x, index) => (
                      <a key={`${index}`}>
                        {x}
                        <br />
                      </a>
                    ))}
                  </TableCell>
                  <TableCell key="tag">
                    {["frustrated", "needs-reschedule"].includes(event.tag) && (
                      <Button
                        color="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteEvent(event.id);
                        }}
                      >
                        borrar
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderExistingComments = () => (
    <div className="mt-5 gap-y-4 flex flex-col">
      {comments.map((comment: Comment, i: number) => (
        <div className="grid grid-cols-12" key={i}>
          <p className="col-span-2">{comment?.user?.fullname}</p>
          <p className="col-span-1">{parseDatetime(comment.created_at)}</p>
          <p className="col-span-8 px-4">{comment.comment}</p>
          <button
            className="bg-transparent text-red-500 text-sm font-bold col-span-1 hover:opacity-50"
            onClick={(e) => {
              e.preventDefault();
              deleteComment(comment.id);
            }}
          >
            borrar
          </button>
        </div>
      ))}
    </div>
  );

  const parseDatetime = (datetime: string) => {
    const timeZone =
      appointment && COUNTRIES.includes(appointment?.country || "")
        ? COUNTRY_TO_TIMEZONE[appointment.country]
        : "America/Santiago";
    const date = new Date(datetime);
    const dateTimeString = date.toLocaleTimeString("it-IT", { timeZone }).split(" ")[0];
    const dateString = date.toLocaleDateString("en-US", { timeZone });
    const timeString = dateTimeString.match(/([0-9]{2}:[0-9]{2})/)?.[0];
    return dateString + " " + timeString;
  };

  function handleChangePaginatorPage(page: number) {
    setCurrentPage(page);
    reloadPatients(page);
  }

  const handleStartAuction = async (): Promise<void> => {
    try {
      Swal.fire({
        icon: "warning",
        title: "¿Estás seguro de que quieres iniciar una subasta?",
        text: "La HT actual ya no podrá ver el appointment",
        confirmButtonText: "Iniciar subasta",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (res) => {
        setAuctionLoading(true);
        if (res.isDenied || res.isDismissed) {
          return;
        }

        try {
          const req = await startAuction(id);
          setHasOngoingAuction(true);
          setAuction(req.data.auction_id);
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `No se pudo iniciar la subasta ${err}`,
          });
        }
        setAuctionLoading(false);
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `No se pudo iniciar la subasta ${err}`,
      });
    }
  };

  return (
    <PrivateRoute>
      {hasOngoingAuction && (
        <div className="w-full bg-red-50 border-b border-red-200 px-4 py-3">
          <div className="flex items-center justify-center gap-x-2">
            <WarningAmberIcon className="text-red-600" />
            <span className="text-red-800">
              Esta cita está actualmente en subasta. Si deseas reasignarla o ver los detalles de la subasta, lo puedes hacer desde el siguiente link:
              <button
                onClick={() => auction && window.open(`https://ops.examedi.com/subastas/${auction}`, '_blank')}
                className="ml-2 text-red-900 underline hover:text-red-700"
              >
                Ver detalles de la subasta
              </button>
            </span>
          </div>
        </div>
      )}

      <div className="p-8 bg-white flex flex-col gap-y-5">
        <AppointmentView
          appointment={appointment}
          payments={payments}
          isLoading={loading}
          className="select-none"
        />
        {/* Actionables skeleton */}
        {loading && (
          <div className="w-full flex flex-col px-16 mt-10 gap-y-5">
            <Skeleton.Input
              active
              className="!w-full"
            />
            <div className="w-full flex justify-between gap-x-10">
              {[...Array(7)].map((_, index) => (
                <Skeleton.Input
                  key={index}
                  active
                  className="!min-w-0 !w-20 !h-5"
                />
              ))}
            </div>
          </div>
        )}
        {!loading && appointment && (
          <div className="w-full flex flex-col">
            <Actionables
              appointmentPatients={appointmentPatients}
              timelineEvents={timelineEvents}
              setTimelineEvents={setTimelineEvents}
              appointment={appointment!}
              appointmentId={id}
              setAppointment={setAppointment}
              setError={setError}
              setLoading={setLoading}
              payments={payments}
            />
            <div className="mt-4 w-full grid grid-cols-2 gap-8 md:grid-cols-1">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  backgroundColor: appointment?.sales_source !== "marketplace" ? "#A5DFD4" : "lightgray",
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={(event, newValue) => setSelectedTab(newValue)}
                  indicatorColor="primary"
                  style={{
                    color: appointment?.sales_source !== "marketplace" ? "#369684" : "#039Be5",
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                  className="tabs"
                >
                  <Tab label="Pacientes" />
                  <Tab label="Pagos" />
                  <Tab label="Boletas" />
                  <Tab label="Comentarios" />
                  <Tab label="Eventos" />
                  <Tab label="Subastas" />
                </Tabs>
              </div>
              <div className="tab-content">
                {selectedTab === TABS.PATIENTS && (
                  <div
                    className="tab-content"
                    style={{ minHeight: "600px", backgroundColor: "#f1f1f1", padding: "1em" }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <div className="p-4">
                        {apptPatientLoading && (
                          <Card className="flex w-fit items-center gap-x-2 p-4 bg-white">
                            <LoadingSpinnerIcon className="w-fit" />
                            <span className="text-md text-gray-600 whitespace-nowrap">Obteniendo pacientes...</span>
                          </Card>
                        )}
                        {!apptPatientLoading && apptPatientError && (
                          <Card className="w-fit p-4 bg-white">
                            <div className="text-base text-gray-600 whitespace-nowrap">
                              Ocurrió un error obteniendo los pacientes
                            </div>
                            {apptPatientError.isAxiosError && (
                              <div className="w-full text-sm">
                                Código de error: {apptPatientError.response?.status || "502"}
                              </div>
                            )}
                            <div className="w-full text-sm mt-1 text-examedi-red-error">
                              {apptPatientError.toString()}
                            </div>
                            <button
                              className="rounded-lg mt-3 px-2 py-1 text-white bg-c1"
                              onClick={async () => {
                                setCurrentPage(1);
                                reloadPatients(1);
                              }}
                            >
                              Reintentar
                            </button>
                          </Card>
                        )}
                      </div>
                      {!apptPatientLoading && !apptPatientError && appointmentPatients.length > 0 && (
                        <div className="w-full flex flex-col gap-y-5">
                          <div className="w-full flex justify-end">
                            <Pagination
                              defaultCurrent={1}
                              current={currentPage}
                              showTotal={(total) =>
                                `Mostrando ${appointmentPatients.length} de un total de ${total} pacientes`
                              }
                              hideOnSinglePage
                              showSizeChanger={false}
                              pageSize={20}
                              total={appointment.appointment_patients.length}
                              onChange={handleChangePaginatorPage}
                            />
                          </div>
                          <PatientInformationList
                            dealGroupedMedicalOrders={appointment.deals.map((deal) => deal.grouped_orden_medica)}
                            setAppointmentPatients={(value) => {
                              setAppointmentPatients(value);
                              reloadPayments();
                            }}
                            appointmentPatients={appointmentPatients}
                            country={appointment.country}
                            appointment={appointment}
                            appointmentId={id}
                            hasImage={hasImage}
                            setHasImage={setHasImage}
                            setReloadOperativeData={setReloadOperativeData}
                            setError={setError}
                            salesSource={appointment.sales_source}
                            className="w-full"
                          />
                          <div className="w-full flex justify-end">
                            <Pagination
                              defaultCurrent={1}
                              current={currentPage}
                              showTotal={(total) =>
                                `Mostrando ${appointmentPatients.length} de un total de ${total} pacientes`
                              }
                              hideOnSinglePage
                              showSizeChanger={false}
                              pageSize={20}
                              total={appointment.appointment_patients.length}
                              onChange={handleChangePaginatorPage}
                            />
                          </div>
                        </div>
                      )}
                        <NewPatient
                          setError={setError}
                          updateAppointmentData={async () => {
                            setCurrentPage(1);
                            reloadPatients(1);
                          }}
                          country={appointment.country}
                          appointmentId={id}
                          setReloadOperativeData={setReloadOperativeData}
                          className="w-full"
                        />
                    </Grid>
                  </div>
                )}

                {selectedTab === TABS.PAYMENTS && (
                  <div
                    className="tab-content"
                    style={{
                      minHeight: "600px",
                      backgroundColor: "#f1f1f1",
                      padding: "1em",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={6}
                      >
                        <AppointmentInfo
                          payments={payments}
                          appointment={appointment}
                          appointmentId={id}
                          setAppointment={setAppointment}
                          setError={setError}
                          cancelComponentRef={cancelComponentRef}
                          appointmentPatients={appointmentPatients}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <Payments
                          payments={payments}
                          appointmentId={id}
                          appointment={appointment}
                          setAppointment={setAppointment}
                          reloadPayments={reloadPayments}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <PaymentDetails data={payments} />
                      </Grid>
                    </Grid>
                  </div>
                )}

                {selectedTab === TABS.RECEIPTS && (
                  <div
                    className="tab-content"
                    style={{
                      minHeight: "600px",
                      backgroundColor: "#f1f1f1",
                      padding: "1em",
                    }}
                  >
                    <div className="bg-gray-200 rounded-lg p-4 inline-block md:flex-basis-45 flex-grow">
                      {appointment?.country === "Chile" && (
                        <ReceiptSection
                          appointmentPatients={appointmentPatients}
                          id={id}
                          receipts={receipts}
                          setReceipts={setReceipts}
                          appointment={appointment}
                          labs={labs}
                        />
                      )}
                    </div>
                  </div>
                )}

                {selectedTab === TABS.COMMENTS && (
                  <div
                    className="tab-content"
                    style={{
                      minHeight: "600px",
                      backgroundColor: "#f1f1f1",
                      padding: "1em",
                    }}
                  >
                    <div className="bg-gray-200 rounded-lg p-4 inline-block md:flex-basis-45 flex-grow">
                      <div className="flex flex-row justify-between items-center">
                        <h3>Comentarios</h3>
                        <ButtonGroup
                          color="primary"
                          variant="text"
                          size="small"
                        >
                          <Button
                            disabled={loadingComments}
                            onClick={createComment}
                          >
                            Agregar comentario
                          </Button>
                        </ButtonGroup>
                      </div>
                      {loading ? <p className="my-2">Cargando comentarios</p> : renderExistingComments()}
                    </div>
                  </div>
                )}

                {selectedTab === TABS.EVENTS && (
                  <div
                    className="tab-content min-h-[600px] bg-[#F1F1F1] p-4 flex flex-col gap-y-4"
                  >
                    <div className="bg-gray-200 rounded-lg p-4 inline-block md:flex-basis-45 flex-grow">
                      <div className="flex flex-row justify-between items-center">
                        <h3>Events</h3>
                      </div>
                      {renderTimelineEvents()}
                    </div>
                    {(isNursePaymentAdmin || isNursePaymentEdit) && <AppointmentNursePaymentCard appointmentId={id} />}
                    {appointment?.is_operative && (
                      <OperativeData
                        id={id}
                        reload={reloadOperativeData}
                        setReload={setReloadOperativeData}
                      />
                    )}
                  </div>
                )}

                {selectedTab === TABS.AUCTIONS && (
                  <div className="p-4 bg-gray-200 rounded-lg">
                    <h2 className="text-2xl font-bold mb-6">Subastas</h2>

                    <div className="bg-white p-4 rounded-lg shadow">
                      <div className="flex flex-col gap-y-4">
                        {auctionLoading ? (
                          <div className="flex justify-center items-center min-h-[100px]">
                            <LoadingSpinnerIcon className="w-8 h-8" />
                          </div>
                        ) : (
                          <>
                            <div className="flex items-center gap-x-3">
                              <span className="font-medium">Estado de subasta:</span>
                              {hasOngoingAuction ? (
                                <div className="flex items-center gap-x-2">
                                  <div className="w-3 h-3 rounded-full bg-green-500 animate-pulse"/>
                                  <span className="text-green-600 font-medium">En subasta activa</span>
                                </div>
                              ) : (
                                <div className="flex items-center gap-x-2">
                                  <div className="w-3 h-3 rounded-full bg-gray-400"/>
                                  <span className="text-gray-600">No está en subasta</span>
                                </div>
                              )}
                            </div>

                            {hasOngoingAuction ? (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '150px' }}
                                onClick={() => {
                                  if (auction) {
                                    window.open(`https://ops.examedi.com/subastas/${auction}`, '_blank');
                                  }
                                }}
                              >
                                Ver detalles
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '150px' }}
                                onClick={() => handleStartAuction()}
                              >
                                Subastar
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <CancelAppointment
        id={id}
        ref={cancelComponentRef}
      />
    </PrivateRoute>
  );
};

export default AppointmentDetail;
