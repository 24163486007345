
export const tableCols = [
  {
    field: "first_name",
    headerName: "primer nombre",
    width: 150,
    sortable: false
  },
  {
    field: "second_name",
    headerName: "segundo nombre",
    width: 150,
    sortable: false
  },
  { field: "last_name", headerName: "Apellido", width: 150, sortable: false },
  {
    field: "second_last_name",
    headerName: "Segundo apellido",
    width: 150,
    sortable: false,
  },
  { field: "email", headerName: "Email", width: 180, sortable: false },
  { field: "phone", headerName: "Teléfono", width: 180, sortable: false },
  {
    field: "rut",
    headerName: "N° documento",
    width: 180,
    sortable: false,
  },
  { field: "gender", headerName: "Sexo", width: 110, sortable: false },
  {
    field: "date_of_birth",
    headerName: "Fecha de nacimiento",
    width: 180,
    sortable: false,
  },
];
