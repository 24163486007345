import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {navigate} from "gatsby";
import {NurseName} from "~/utils/interfaces/Nurse";

interface Props {
    worker: NurseName;
}

const NurseCard = ({worker}: Props): JSX.Element => {
    const names = `${worker.names} ${worker.last_names}`;
    const picture = worker.profile_picture;
    const id = worker.id;
    const specialties = worker.specialties.map((x) => x.name).join(", ");
    const showTagOperativesOnly = worker.type_of_appointment === 'operative';
    return (<Card
        variant="outlined"
        onClick={() => {
            navigate(`/health-team/${id}/`);
        }}
    >
        <CardActionArea>
            <div className={'absolute grid justify-items-end right-0'}>
                {worker.fake &&
                    <p className='bg-white rounded p-2'>Fantasma</p>}
                {showTagOperativesOnly &&
                    <p className='bg-white rounded p-2'>Solo operativos</p>}
            </div>
            <CardMedia
                image={picture}
                className="h-48"
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="h3"
                >
                    {names}
                </Typography>
                {specialties && (<Typography
                    gutterBottom
                    variant="subtitle1"
                    component="h3"
                >
                    ({specialties})
                </Typography>)}
            </CardContent>
        </CardActionArea>
    </Card>)
};

export default NurseCard;
