import React, {useEffect, useState} from "react";

import {Button, createTheme, ThemeProvider} from "@material-ui/core";
import {globalHistory} from "@reach/router";
import {navigate} from "gatsby";
import {
    FaBookMedical,
    FaClinicMedical,
    FaUser,
    FaWpforms
} from "react-icons/fa";
import {FiPackage} from "react-icons/fi";
import {IoCloudOffline} from "react-icons/io5";
import {
    MdDashboard,
    MdDataUsage,
    MdDiscount,
    MdMap,
    MdPayments,
    MdShoppingCart,
    MdSupervisorAccount,
} from "react-icons/md";
import {TiArrowRepeat} from "react-icons/ti";

import clsx from "clsx";
import LoadingError from "~/components/Loaders/LoadingError";
import Navbar from "~/components/nav/Navbar";
import FilterStore from "~/components/Stores/FilterStore";
import authenticationService from "~/utils/api/v1/authenticationService";
import Auth from "~/utils/auth/auth";
import {isProd} from "~/utils/environment";

const auth = new Auth();


export const logoAsset = require(`~/assets/branding/examedi-logo-2023.png`).default;

export const navs = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: <MdDashboard className="text-[#039BE5]"/>,
    },
    {
        url: `https://${isProd ? "" : "staging-"}ops.examedi.com/telehealths/`,
        name: "Telemedicina",
        icon: <MdDashboard className="text-[#039BE5]"/>,
        external: true,
        requiredRole: "employee",
    },
    {
        path: "/users",
        name: "Usuarios",
        icon: <FaUser className="text-[#039BE5]"/>,
    },
    {
        path: "/patients",
        name: "Pacientes",
        icon: <FaBookMedical className="text-[#039BE5]"/>,
    },
    {
        path: "/nurse-payments",
        name: "Pagos HT",
        icon: <MdPayments className="text-[#039BE5]"/>,
        requiredRole: "nurse_payment_admin",
    },
    {
        path: "/capacity",
        name: "Capacity",
        icon: <MdDataUsage className="text-[#039BE5]"/>,
    },
    {
        path: "/health-team",
        name: "Health Team",
        icon: <MdSupervisorAccount className="text-[#039BE5]"/>,
    },
    {
        path: "/sectors",
        name: "Sectores",
        icon: <MdMap className="text-[#039BE5]"/>,
    },
    {
        path: "/laboratory",
        name: "Laboratorios",
        icon: <FaClinicMedical className="text-[#039BE5]"/>,
    },
    {
        path: "/offline-appointment/create",
        name: "Operativos CL",
        icon: <IoCloudOffline className="text-[#039BE5]"/>,
    },
    {
        path: "/offline-appointment-mx/create",
        name: "Offline MX",
        icon: <IoCloudOffline className="text-[#039BE5]"/>,
    },
    {
        path: "/offline-appointment/assisted-schedule",
        name: "Agendamiento asistido",
        icon: <IoCloudOffline className="text-[#039BE5]"/>,
    },
    {
        path: "/checkout-builder/",
        name: "Carrito Maker",
        icon: <MdShoppingCart className="text-[#039BE5]"/>,
    },
    {
        path: "/medical-orders",
        name: "Órdenes médicas",
        icon: <FaWpforms className="text-[#039BE5]"/>,
    },
    {
        path: "/subscriptions",
        name: "Suscripciones",
        icon: <TiArrowRepeat className="text-[#039BE5]"/>,
    },
    {
        path: "/coupons",
        name: "Cupones",
        icon: <MdDiscount className="text-[#039BE5]"/>,
    },
    {
        path: "/new-packs/",
        name: "Packs Nuevos",
        icon: <FiPackage className="text-[#039BE5]"/>,
    },
    {
        path: "/dashboard/totalpack",
        name: "Total Pack",
        icon: <MdDashboard className="text-[#039BE5]"/>,
    },
    {
        path: "/pending",
        name: "Resultados por Revisar",
        icon: <MdMap className="text-[#039BE5]"/>,
    },
    {
        path: "/refunds",
        name: "Reembolsos Cancelaciones",
        icon: <FaWpforms className="text-[#039BE5]"/>,
    },
];
