import React, {useEffect, useState} from "react";

import {Button, createTheme, ThemeProvider} from "@material-ui/core";
import {globalHistory} from "@reach/router";
import {navigate} from "gatsby";

import clsx from "clsx";
import LoadingError from "~/components/Loaders/LoadingError";
import Navbar from "~/components/nav/Navbar";
import FilterStore from "~/components/Stores/FilterStore";
import authenticationService from "~/utils/api/v1/authenticationService";
import Auth from "~/utils/auth/auth";
import {navs} from "~/components/Containers/navs";

const auth = new Auth();

function TailwindResponsiveHint() {
    const [isTailwindEnabled, setIsTailwindEnabled] = useState(false);

    useEffect(() => {
        // Escucha cambios en el valor de window.enableFeature
        const interval = setInterval(() => {
            setIsTailwindEnabled(window.tailwind_hint || false);
        }, 100); // Puedes ajustar la frecuencia de actualización
        return () => clearInterval(interval);
    }, []);
    return (<div>{isTailwindEnabled && <div
        className={clsx("fixed", "top-0", "left-0", "z-[60]", "text-sm", "leading-3")}>
        <div className="block xxs:hidden">base</div>
        <div className="hidden xxs:block xs:hidden">xxs</div>
        <div className="hidden xs:block sm:hidden">xs</div>
        <div className="hidden sm:block md:hidden">sm</div>
        <div className="hidden md:block lg:hidden">md</div>
        <div className="hidden lg:block 2lg:hidden">lg</div>
        <div className="hidden 2lg:block xl:hidden">2lg</div>
        <div className="hidden xl:block 1xl:hidden">xl</div>
        <div className="hidden 1xl:block 2xl:hidden">1xl</div>
        <div className="hidden 2xl:block 3xl:hidden">2xl</div>
        <div className="hidden 3xl:block">3xl</div>
    </div>}</div>)
}

const logoAsset = require(`~/assets/branding/examedi-logo-2023.png`).default;
export const theme = createTheme({
    palette: {
        primary: {
            light: "#4eb9ec",
            main: "#039Be5",
            dark: "#027cb7",
            contrastText: "#001f2d",
            warning: "#ffc302",
            success: "#00ff00",
            error: "#FF0505",
        },
        secondary: {
            main: "#FF6D70",
        },
    },
});

const MainLayout = (props: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [showLayout, setShowLayout] = useState(window.location.pathname !== "/");
    const [active, setActive] = useState<string>("Dashboard");

    const fetchRefreshToken = async () => {
        setLoading(true);
        try {
            await authenticationService.refreshAccessToken();
        } catch (err) {
            console.log(err);
            auth.logout(() => {
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        return globalHistory.listen((routeChange) => {
            if (routeChange.action === "PUSH") {
                setShowLayout(routeChange.location.pathname !== "/");
            }
        });
    }, []);

    useEffect(() => {
        const accessToken = auth.getAccessToken();
        const refreshToken = auth.getRefreshToken();
        if ([accessToken, refreshToken].includes("")) {
            setLoading(false);
            return;
        }
        const lastExecution = localStorage.getItem("lastExecutionTimestamp");
        const currentDate = new Date().getTime();
        const twentyFourHours = 24 * 60 * 60 * 1000;
        const hasToRefreshToken = !lastExecution || currentDate - lastExecution >= twentyFourHours;
        if (hasToRefreshToken) {
            fetchRefreshToken();
            localStorage.setItem("lastExecutionTimestamp", currentDate);
        }
        setLoading(false);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <link
                rel="stylesheet"
                href="https://unpkg.com/leaflet@1.9.1/dist/leaflet.css"
                integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14="
                crossOrigin=""
            />
            <script
                src="https://unpkg.com/leaflet@1.9.1/dist/leaflet.js"
                integrity="sha256-NDI0K41gVbWqfkkaHj15IzU7PtMoelkzyKp8TOaFQ3s="
                crossOrigin=""
            />
            <FilterStore>
                <>
                    <TailwindResponsiveHint/>
                    <div
                        className="text-left min-h-screen grid grid-cols-12 w-full">
                        {auth.isLoggedIn() && (
                            <div
                                className="hidden md:grid fixed bg-gray-100 col-span-1 border-r-2 border-gray-100 overflow-y-scroll overflow-x-clip h-screen">
                                <div
                                    className="flex items-center justify-center"
                                >
                                    <img
                                        className="w-fit h-6 object-contain mt-4"
                                        src={logoAsset}/>
                                </div>
                                <div className="mt-2">
                                    {navs.map((item, i) => {
                                        const {requiredRole} = item;
                                        if (requiredRole && !auth.hasRole(requiredRole)) {
                                            return null;
                                        }
                                        return (
                                            <button
                                                key={i}
                                                className={clsx(
                                                    "w-full flex items-center p-4 hover:!bg-gray-100 hover:opacity-30 hover:scale-105 transition-all duration-100 ease-in-out no-underline",
                                                    active === item.name && "bg-gray-200",
                                                    active !== item.name && "bg-white",
                                                )}
                                                onClick={() => {
                                                    if (item.external) {
                                                        window.location.href = item.url;
                                                    } else {
                                                        navigate(item.path as string);
                                                        setActive(item.name);
                                                    }
                                                }}
                                            >
                                                {item.icon} <h6
                                                className="ml-5 mb-0 font-medium text-left">{item.name}</h6>
                                            </button>
                                        );
                                    })}
                                    <button
                                        className="flex items-center p-4 pl-5 hover:bg-gray-100">
                                        <Button
                                            onClick={() => auth.logout(async () => navigate("/"))}>Cerrar
                                            Sesión</Button>
                                    </button>
                                </div>
                            </div>
                        )}
                        <div
                            className={clsx("w-full bg-gray-100 col-span-full flex flex-col col-start-1", showLayout && auth.isLoggedIn() && "col-start-3 col-span-10 px-20")}>
                            {showLayout && auth.isLoggedIn() && <Navbar/>}
                            {loading && (
                                <LoadingError
                                    loading={loading}
                                    error={false}
                                />
                            )}
                            {!loading && <div
                                className={clsx(showLayout && auth.isLoggedIn() && "mt-16")}>{props.children}</div>}
                        </div>
                    </div>
                </>
            </FilterStore>
        </ThemeProvider>
    );
};

export default MainLayout;
