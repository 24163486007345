import React, {useEffect, useState} from "react";

import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import {navigate} from "gatsby-link";
import {format, validate} from "rut.js";
import Swal from "sweetalert2";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import nurseService from "~/utils/api/v1/nurseService";
import estadoMunicipio from "~/utils/comunas/estado_municipio.json";
import {comunas} from "~/utils/comunas/names";
import {Specialty} from "~/utils/interfaces/Nurse";
import {NewNurse} from "~/utils/interfaces/User";
import {validateEmail} from "~/utils/regex";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {DataGrid} from "@material-ui/data-grid";
import {tableCols} from "~/constants/fileLoader/nurse";
import nurseAPI from "../../utils/api/v2/nurse";
import CircularProgress from "@material-ui/core/CircularProgress";
import FileImporter from "~/components/FileImporter";

const fileHeadersToKeys: Record<string, string> = {
    "Primer nombre": "first_name",
    "Segundo nombre": "second_name",
    Apellido: "last_name",
    "Segundo apellido": "second_last_name",
    "Fecha de nacimiento": "date_of_birth",
    "Correo electrónico": "email",
    Sexo: "gender",
    Teléfono: "phone",
    "Número de documento": "rut",
};

const defaultHT = {
    email: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    rut: "",
    date_of_birth: "",
    gender: "male",
    pcr: false,
    blood: false,
    comunas: [],
    fingerprint_available: false,
    country: "cl",
    specialties: [],
    type_of_appointment: 'regular',
} as NewNurse;

const NewHealthTeamMember = (): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [country, setCountry] = useState<string>("Chile");
    const [logText, setLogText] = useState<string>("");
    const [comunasOptions, setComunasOptions] = useState<string[]>(comunas);
    const [specialtyOptions, setSpecialtyOptions] = useState<string[]>([]);
    const [isImporterOpen, setIsImporterOpen] = useState<boolean>(false);
    const [isMasiveImport, setIsMasiveImport] = useState<boolean>(false);
    const [newNurses, setNewNurses] = useState<NewNurse[]>([]);

    const [userData, setUserData] = useState<NewNurse>(defaultHT);


    useEffect(() => {
        const fetchNurseSpecialties = async () => {
            const res = await nurseService.fetchNurseSpecialties();
            const specialties = res.data.map((speciality: Specialty) => speciality.name);
            setSpecialtyOptions(specialties);
        };
        fetchNurseSpecialties();
    }, []);

    const createGenericIdMx = () => {
        const parsedBirthDate = userData.date_of_birth?.split("-").join("");
        const genericId = `${userData.first_name}${userData.last_name}${parsedBirthDate}`.toUpperCase();
        return genericId;
    };

    const onSubmit = async () => {
        setLoading(true);
        setLogText('');
        let nursesToCreate = []
        if (isMasiveImport) {
            newNurses.forEach(nurse => {
                const dataNewNurse = userData.country === "mx" ? {
                    ...userData,
                    rut: createGenericIdMx()
                } : userData;
                nursesToCreate.push({...dataNewNurse, ...nurse});
            })
        } else {
            const dataNewNurse = userData.country === "mx" ? {
                ...userData,
                rut: createGenericIdMx()
            } : userData;
            const errors = checkErrors(dataNewNurse);
            if (errors) {
                setLoading(false);
                return;
            }
            nursesToCreate.push(dataNewNurse);
        }
        const displayAmount = nursesToCreate.length;
        let counter = 1;
        let success = true;
        for (const nurse of nursesToCreate) {
            counter++;
            try {
                let message = `Creando HT: ${nurse.email}`;
                if (displayAmount > 1) {
                    message += ` ${counter} de ${displayAmount}`;
                }
                setLogText(message);
                await nurseAPI.create(nurse);
            } catch (err) {
                success = false;
                console.error(err);
                setErrors([err.message]);
            }
        }
        setLogText('');
        Swal.fire({
            icon: !success ? "error" : "success",
            title: !success ? "Tomador con ese RUT ya existe en la base de datos" : "HT creado con éxito!",
            text: '',
            showConfirmButton: true,
            didClose: () => {
                navigate(-1);
            },
        });
        setLoading(false);
    };

    const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = event.target.value;
        const attribute = event.target.id;
        setUserData((prevState: NewNurse): NewNurse => {
            const newState = {...prevState};
            newState[attribute] = attribute === "rut" ? format(newValue) : newValue;
            return newState;
        });
    };

    const changeCountry = (event): void => {
        setCountry(event.target.value);
        if (event.target.value === "Chile") {
            setComunasOptions(comunas);
            setUserData({...userData, country: "cl"});
        } else if (event.target.value === "México") {
            setUserData({...userData, country: "mx"});
            setComunasOptions([...estadoMunicipio["Ciudad de México"], ...estadoMunicipio["Estado de México"]]);
        }
    };

    const genderChange = (event): void => {
        setUserData((prevState: NewNurse): NewNurse => {
            const newState = {...prevState};
            newState.gender = event.target.value;
            return newState;
        });
    };

    const checkErrors = (data): boolean => {
        const newErrors: string[] = [];

        // check for errors in the form
        if (data.first_name.length === 0) {
            newErrors.push("El nombre es obligatorio");
        }
        if (data.last_name.length === 0) {
            newErrors.push("El apellido es obligatorio");
        }
        if (data.country === "cl" && !validate(data.rut ? data.rut : "")) {
            newErrors.push("El RUT no es válido");
        }
        if (!validateEmail(data.email)) {
            newErrors.push("El email no es válido");
        }

        setErrors(newErrors);
        return newErrors.length ? true : false;
    };

    function handleFileImporterSubmit(data: NewNurse[]) {
        setNewNurses(data);
    }

    return (
        <PrivateRoute>
            <div className="flex justify-center">
                <div
                    className="p-12 rounded-lg bg-white border border-gray-200 flex flex-col">
                    <h2 className="mb-8">Crear nuevo miembro del HealthTeam</h2>
                    {loading && (
                        <div
                            className="grid flex justify-center items-center p-8">
                            <CircularProgress/>
                            <div>{logText}</div>
                        </div>
                    )}
                    <FormControlLabel
                        control={
                            <Switch
                                name="Importador masivo"
                                color="primary"
                                checked={isMasiveImport}
                                onChange={() => setIsMasiveImport(!isMasiveImport)}
                                disabled={loading}
                            />
                        }
                        label="Importador masivo"
                    />
                    <div className="text-red-500 flex justify-center">
                        <ul>
                            {errors.map((i, key) => (
                                <li key={key}>{i}</li>
                            ))}
                        </ul>
                    </div>
                    <div
                        className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
                        <FormControl>
                            <Select
                                value={country}
                                onChange={changeCountry}
                                className="w-20"
                                disabled={loading}
                            >
                                {["Chile", "México"].map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>País</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth>
                            <Select
                                multiple
                                fullWidth
                                value={userData.comunas}
                                onChange={(event) =>
                                    setUserData((prevState: NewNurse): NewNurse => {
                                        const newState = {...prevState};
                                        newState.comunas = event.target.value as string[];
                                        return newState;
                                    })
                                }
                                input={<Input id="select-multiple-chip"/>}
                                renderValue={(selected) => (
                                    <div
                                        className="flex flex-wrap max-w-500">
                                        {(selected as string[]).map((value) => (
                                            <Chip
                                                key={value}
                                                label={value}
                                                className="m-2"
                                            />
                                        ))}
                                    </div>
                                )}
                                disabled={loading}

                            >
                                {comunasOptions.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{country === "Chile" ? "Comunas" : "Alcaldías"}</FormHelperText>
                        </FormControl>
                    </div>
                    {isMasiveImport ? <>
                        <ButtonGroup variant="text">
                            <Button
                                color="primary"
                                onClick={() => setIsImporterOpen(true)}
                            >
                                Importar
                            </Button>
                            <Button color="primary">
                                <a
                                    target="_blank"
                                    href="https://flatfile-templates.s3.us-east-1.amazonaws.com/upload_HT_templates.xlsx"
                                    rel="noreferrer"
                                    style={{
                                        color: "inherit",
                                        textDecoration: "none"
                                    }}
                                >
                                    Descargar plantilla
                                </a>
                            </Button>
                        </ButtonGroup>
                        <div className="w-full h-96">
                            <DataGrid
                                rows={newNurses.map((user: NewNurse, key: number) => ({
                                    ...user,
                                    id: key,
                                }))}
                                columns={tableCols}
                                pageSize={15}
                                rowsPerPageOptions={[15]}
                                disableColumnMenu
                                disableColumnFilter
                                disableColumnSelector
                                disableSelectionOnClick
                                getRowClassName={(params: any) => (params.row.hasError ? "bg-red-100" : "")}
                            />
                        </div>
                    </> : (<>
                        <div
                            className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
                            <TextField
                                id="first_name"
                                onChange={formChange}
                                value={userData.first_name}
                                helperText="Primer nombre"
                                fullWidth
                            ></TextField>
                            <TextField
                                id="second_name"
                                onChange={formChange}
                                value={userData.second_name}
                                helperText="Segundo nombre"
                                fullWidth
                            ></TextField>
                        </div>
                        <div
                            className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
                            <TextField
                                id="last_name"
                                onChange={formChange}
                                value={userData.last_name}
                                helperText="Apellido"
                                fullWidth
                            ></TextField>
                            <TextField
                                id="second_last_name"
                                onChange={formChange}
                                value={userData.second_last_name}
                                helperText="Segundo apellido"
                                fullWidth
                            ></TextField>
                        </div>
                        <div
                            className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
                            <TextField
                                id="email"
                                onChange={formChange}
                                value={userData.email}
                                helperText="Email"
                                fullWidth
                            ></TextField>
                        </div>
                        <div
                            className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
                            <TextField
                                id="phone"
                                onChange={formChange}
                                value={userData.phone}
                                helperText="Teléfono"
                                fullWidth
                            ></TextField>
                            {country === "Chile" && (
                                <TextField
                                    id="rut"
                                    onChange={formChange}
                                    value={userData.rut}
                                    helperText="RUT"
                                    fullWidth
                                ></TextField>
                            )}
                        </div>
                        <div
                            className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
                            <FormControl fullWidth>
                                <Select
                                    onChange={genderChange}
                                    value={userData.gender}
                                    fullWidth
                                    disabled={loading}

                                >
                                    <MenuItem value="male">Hombre</MenuItem>
                                    <MenuItem value="female">Mujer</MenuItem>
                                    <MenuItem value="other">Otro</MenuItem>
                                </Select>
                                <FormHelperText>Sexo</FormHelperText>
                            </FormControl>
                            <TextField
                                fullWidth
                                id="date_of_birth"
                                helperText="Fecha de nacimiento"
                                type="date"
                                onChange={formChange}
                                value={userData.date_of_birth}
                                disabled={loading}

                            />
                        </div>
                        <div
                            className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
                            <FormControl fullWidth>
                                <Select
                                    multiple
                                    fullWidth
                                    value={userData.specialties}
                                    onChange={(event) =>
                                        setUserData((prevState: NewNurse): NewNurse => {
                                            const newState = {...prevState};
                                            newState.specialties = event.target.value as string[];
                                            return newState;
                                        })
                                    }
                                    input={<Input id="select-multiple-chip"/>}
                                    renderValue={(selected) => (
                                        <div
                                            className="flex flex-wrap max-w-500">
                                            {(selected as string[]).map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    className="m-2"
                                                />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {specialtyOptions?.map((specialty) => (
                                        <MenuItem
                                            key={specialty}
                                            value={specialty}
                                        >
                                            {specialty}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>Especialidades</FormHelperText>
                            </FormControl>
                        </div>

                    </>)}
                    <div
                        className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
                        <FormControlLabel
                            control={
                                <Switch
                                    name="PCR"
                                    color="primary"
                                    checked={userData.pcr}
                                    onChange={() =>
                                        setUserData((prevState: NewNurse): NewNurse => {
                                            const newState = {...prevState};
                                            newState.pcr = !newState.pcr;
                                            return newState;
                                        })
                                    }
                                />
                            }
                            label="PCR"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    name="Sangre"
                                    color="primary"
                                    checked={userData.blood}
                                    onChange={() =>
                                        setUserData((prevState: NewNurse): NewNurse => {
                                            const newState = {...prevState};
                                            newState.blood = !newState.blood;
                                            return newState;
                                        })
                                    }
                                />
                            }
                            label="Sangre"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    name="¿Tiene huellero?"
                                    color="primary"
                                    checked={userData.fingerprint_available}
                                    onChange={() =>
                                        setUserData((prevState: NewNurse): NewNurse => {
                                            const newState = {...prevState};
                                            newState.fingerprint_available = !newState.fingerprint_available;
                                            return newState;
                                        })
                                    }
                                />
                            }
                            label="¿Tiene huellero?"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    name="¿Solo operativos?"
                                    color="primary"
                                    checked={userData.type_of_appointment === 'operative'}
                                    onChange={() =>
                                        setUserData((prevState: NewNurse): NewNurse => {
                                            const newState = {...prevState};
                                            newState.type_of_appointment = userData.type_of_appointment === 'regular' ? 'operative' : 'regular';
                                            return newState;
                                        })
                                    }
                                />
                            }
                            label="¿Solo operativos?"
                        />
                    </div>

                    <div
                        className="flex align-center justify-center my-8 gap-x-4">
                        <button
                            className="text-red-500 bg-white text-sm hover:opacity-70 hover:bg-red-500/10 uppercase border-[1.5px] border-red-500 rounded-md px-12 py-2"
                            disabled={loading}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            {loading ? <CircularProgress size={20}
                                                         color={'secondary'}/> : "Cancelar"}
                        </button>
                        <button
                            className="text-[#039BE5] bg-white text-sm hover:opacity-70 uppercase border-[1.5px] border-[#039BE5] rounded-md px-12 py-2"
                            disabled={loading}
                            onClick={onSubmit}
                        >
                            {loading ? <CircularProgress size={20}/> : "Crear"}
                        </button>
                    </div>
                </div>
                <FileImporter
                    isOpen={isImporterOpen}
                    setIsOpen={setIsImporterOpen}
        tableCols={tableCols}
                    name="Importar HTs"
                    fileHeadersToKeys={fileHeadersToKeys}
                    onConfirm={(data) => handleFileImporterSubmit(data as unknown as NewNurse[])}
                />
            </div>
        </PrivateRoute>
    );
};

export default NewHealthTeamMember;
